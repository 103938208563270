
import {defineComponent, onMounted, ref} from 'vue';
import Tinymce from '@/components/Tinymce.vue';
import {getNewDetail} from '@/api/webIndex'
import {useRoute} from "vue-router";

export default defineComponent({
  name: 'newDetail',
  components: {
    Tinymce
  },
  setup() {
    const route = useRoute()

    const
        detail = ref({})

    const
        onGetDetail = async (id: any) => {
          try {
            let res = await getNewDetail({id})
            if (res.message === 'ok') {
              detail.value = res.data
            }
          } catch (e) {
            console.log(e)
          }
        }

    onMounted(() => {
      onGetDetail(route.query.id)
    })

    return {
      onGetDetail,
      detail
    };
  }
});
