import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77ad7eee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-wrap w-100p" }
const _hoisted_2 = { class: "tinymce-wrap w-1000 p-b-100" }
const _hoisted_3 = { class: "f-s-28 col-b-title m-t-16" }
const _hoisted_4 = { class: "f-s-14 col-b-title m-t-10 opacity-3 m-b-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tinymce = _resolveComponent("tinymce")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.detail.title), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.detail.created_at), 1),
      _createVNode(_component_tinymce, {
        content: _ctx.detail.content
      }, null, 8, ["content"])
    ])
  ]))
}